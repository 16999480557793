<template>
  <header class="header-area fixed-top">
    <div class="nav-area">
      <div
        :class="[
          'navbar-area',
          { 'is-sticky': isSticky },
          { 'is-nav-moblie-scroll': !isNavMoblieScroll },
        ]"
      >
        <div class="main-nav">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
              <router-link
                :class="['navbar-brand']"
                class=""
                :to="this.$i18n.bindLang('/')"
              >
                <div>
                  <img
                    class="nav-logo-image main"
                    src="~assets/project_images/svg/item_KEC-logo-white.svg"
                    alt="image"
                  />
                </div>
              </router-link>

              <b-navbar-toggle target="navbarSupportedContent">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
              </b-navbar-toggle>

              <b-collapse
                class="collapse navbar-collapse mean-menu text-center"
                id="navbarSupportedContent"
                is-nav
              >
                <ul class="navbar-nav m-auto">
                  <li class="nav-item">
                    <div class="nav-li-font" @click="isVisitorFun">
                      {{ $t("navbar.menu_1") }}
                    </div>
                    <transition name="bounce">
                      <ul class="dropdown-menu" v-show="isVisitor">
                        <!-- <li class="nav-item">
                                                    <router-link to="/" class="nav-link">
                                                        {{ navNameVisitor }}
                                                    </router-link>
                                                </li> -->
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/visitors-exhibition')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_1_1") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/traffic')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_1_2") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/visitors-facility')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_1_3") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/accommodation')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_1_5") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a
                            :href="
                              'https://khh.travel/' +
                                (this.$i18n.locale == 'en-us' ? 'en' : '')
                            "
                            target="_blank"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_1_4") }}
                          </a>
                        </li>
                      </ul>
                    </transition>
                  </li>

                  <li class="nav-item">
                    <div class="nav-li-font" @click="isOrganizersFun">
                      {{ $t("navbar.menu_2") }}
                    </div>
                    <transition name="bounce">
                      <ul class="dropdown-menu" v-show="isOrganizers">
                        <!-- <li class="nav-item">
                                                    <router-link to="/organizers" class="nav-link">
                                                        {{ navNameOrganizers }}
                                                    </router-link>
                                                </li> -->
                        <!--
                                                <li class="nav-item">
                                                    <router-link to="/external-link" class="nav-link">
                                                        {{ $t('navbar.menu_2_1') }}
                                                    </router-link>
                                                </li>
                                                -->

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/rental-venue')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_2_3") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/organizers-facility')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_2_4") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/organizers-album')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_2_5") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/organizers-document')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_2_6") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a
                            href="https://5gsmartvenue.com.tw"
                            target="_blank"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_2_2") }}
                          </a>
                        </li>
                      </ul>
                    </transition>
                  </li>

                  <li class="nav-item">
                    <div class="nav-li-font" @click="isNewsFun">
                      {{ $t("navbar.menu_3") }}
                    </div>
                    <transition name="bounce">
                      <ul class="dropdown-menu" v-show="isNews">
                        <!-- <li class="nav-item">
                                                    <router-link to="/press" class="nav-link">
                                                        {{ navNamePress }}
                                                    </router-link>
                                                </li> -->

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/news')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_3_1") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/press-album')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_3_2") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/video')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_3_3") }}
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/press-document')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_3_4") }}
                          </router-link>
                        </li>
                      </ul>
                    </transition>
                  </li>

                  <li class="nav-item">
                    <div class="nav-li-font" @click="isAboutFun">
                      {{ $t("navbar.menu_4") }}
                    </div>
                    <transition name="bounce">
                      <ul class="dropdown-menu" v-show="isAbout">
                        <!-- <li class="nav-item">
                                                    <router-link to="/" class="nav-link">
                                                        {{ navNameCompany }}
                                                    </router-link>
                                                </li> -->
                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/kec')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_4_1") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/esg')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_4_5") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/team')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_4_2") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/join-us')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_4_3") }}
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link
                            :to="this.$i18n.bindLang('/contact-us')"
                            class="nav-link"
                          >
                            {{ $t("navbar.menu_4_4") }}
                          </router-link>
                        </li>
                      </ul>
                    </transition>
                  </li>
                </ul>

                <LangSelect v-show="true" />
              </b-collapse>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LangSelect from "./LangSelect";
export default {
  name: "NavbarStyleTwo",
  components: {
    LangSelect,
  },
  data() {
    return {
      isSticky: false,
      isActive: false,
      notActive: false,
      isVisitor: window.innerWidth >= 576 ? true : false,
      isOrganizers: window.innerWidth >= 576 ? true : false,
      isNews: window.innerWidth >= 576 ? true : false,
      isAbout: window.innerWidth >= 576 ? true : false,
      isNavWidth: window.innerWidth >= 991 ? true : false,
      isNavMoblieScroll: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
        that.isActive = true;
        that.notActive = false;
        that.isNavMoblieScroll = true;
      } else {
        that.isSticky = false;
        that.isActive = false;
        that.notActive = true;
        that.isNavMoblieScroll = false;
      }
    });

    window.addEventListener("resize", () => {
      that.isVisitor = window.innerWidth >= 576 ? true : false;
      that.isOrganizers = window.innerWidth >= 576 ? true : false;
      that.isNews = window.innerWidth >= 576 ? true : false;
      that.isAbout = window.innerWidth >= 576 ? true : false;
      that.isNavWidth = window.innerWidth >= 991 ? true : false;
    });
  },
  methods: {
    closeToggle() {
      this.isVisitor = false;
      this.isOrganizers = false;
      this.isNews = false;
      this.isAbout = false;
    },
    isVisitorFun() {
      if (window.innerWidth <= 576) {
        this.closeToggle();
        this.isVisitor = !this.isVisitor;
      }
    },
    isOrganizersFun() {
      if (window.innerWidth <= 576) {
        this.closeToggle();
        this.isOrganizers = !this.isOrganizers;
      }
    },
    isNewsFun() {
      if (window.innerWidth <= 576) {
        this.closeToggle();
        this.isNews = !this.isNews;
      }
    },
    isAboutFun() {
      if (window.innerWidth <= 576) {
        this.closeToggle();
        this.isAbout = !this.isAbout;
      }
    },
  },
};
</script>

<style>
.is-nav-width {
  display: none;
}
.banner-area .container {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .mobile-nav-hidden {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }

  nav {
    padding: 0 !important;
  }

  .navbar-brand {
    margin-right: 0rem;
  }

  .split-li-icon {
    font-size: 25px;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 35px;
    padding-left: 35px;
    color: #dedbdb;
  }

  .scroll-container {
    position: relative;
  }

  .scroll-nav-menu {
    position: absolute;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    border-bottom-left-radius: 10px;
    top: 0;
    right: 0;
    width: 70%;
  }

  .scroll-nav-menu a {
    color: #000000 !important;
    font-size: 20px !important;
  }

  .scroll-nav-menu a:hover {
    color: #2680ff !important;
  }

  .scroll-nav-logo-image {
    width: 200px;
    height: 100%;
  }

  .scroll-nav-logo {
    background-color: #ffffff;
    padding: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* margin-right: 100px; */
    max-width: 10%;
  }

  .scroll-lang-block {
    position: absolute;
    right: 32.5px;
    top: 22.5px;
  }

  .not-active {
    display: none;
  }

  .is-active {
    display: none;
  }
}
.features-title-box {
  color: #000033;
}
.community-btn {
  margin: 0.5vw;
  font-size: 25px;
  color: #ffffff;
}

.nav-logo-image {
  height: 60px;
}
.nav-logo-image.main {
  width: 100px;
}
.nav-area .navbar-area .main-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid rgb(0 0 0 / 0%);
  background-color: rgba(17, 19, 45, 0);
  /* background-color: #000034 !important; */
}
/*.nav-li-font {
        padding: 0 3rem!important;
    }*/

.main-nav nav .navbar-nav .nav-item:hover .nav-li-font {
  cursor: pointer;
  color: #ffff00 !important;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  width: 11.3rem;
}

.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
  color: #ffff00;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  text-align: center;
}

.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  color: #212529 !important;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 0.25rem;
}

.nav-area
  .navbar-area
  .main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a:hover {
  color: #2680ff !important;
  background-color: #f8f9fa;
}

.navbar-area.is-sticky .main-nav .navbar .container-fluid .mean-menu ul li a {
  color: #000000 !important;
}

.nav-area .container-fluid {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 992px) {
  .navbar-toggler .icon-bar {
    width: 28px;
    transition: all 0.3s;
    background: #ffffff;
    height: 2px;
    display: block;
  }
  .nav-area .navbar-area.is-sticky .main-nav {
    background-color: #000033 !important;
  }
  .main-nav nav ul {
    padding: 15px 0;
  }
  .nav-area
    .navbar-area
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu {
    transition: opacity 0.5s;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 1s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.05);
    }

    100% {
      transform: scale(1);
    }
  }

  .nav-area
    .navbar-area.is-sticky
    .main-nav
    .navbar
    .container-fluid
    #navbarSupportedContent
    .navbar-nav
    .nav-item
    .nav-li-font {
    font-size: 18px;
    color: #000000;
    text-transform: capitalize;
    transition: all 0.5s;
    margin-left: 0;
    padding: 0 1.2rem;
    position: relative;
    letter-spacing: 2px;
    padding-bottom: 5px;
  }
}

.nav-area
  .navbar-area
  .main-nav
  .navbar
  .container-fluid
  #navbarSupportedContent
  .navbar-nav
  .nav-item
  .nav-li-font {
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  transition: all 0.5s;
  margin-left: 0;
  padding: 0 1.2rem;
  position: relative;
  letter-spacing: 2px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.nav-area
  .navbar-area.is-sticky
  .main-nav
  .navbar
  .container-fluid
  #navbarSupportedContent
  .navbar-nav
  .nav-item
  .nav-li-font {
  color: #ffffff;
}

.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.nav-area .navbar-area.is-sticky {
  background-color: #000034;
}

.navbar-toggler {
  position: relative;
  right: 0;
}

/* .nav-area .navbar-area .main-nav .navbar .navbar-collapse {
        overflow: hidden;
    } */
</style>
