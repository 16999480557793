var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hot-button" }, [
    _c("a", { attrs: { href: this.$i18n.bindLang("/contact-us") } }, [
      _c(
        "div",
        {
          class: [
            "email-btn",
            { "is-active": _vm.isSticky },
            "animated pulse infinite"
          ]
        },
        [
          _c("b-icon", {
            staticClass: "icon-color",
            attrs: { icon: "envelope-fill", "aria-hidden": "true" }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        class: ["top-scroll-speed", { "is-active": _vm.isSticky }],
        on: { click: _vm.backToTop }
      },
      [
        _c("b-icon", { staticClass: "icon-color", attrs: { icon: "arrow-up" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }