<template>
  <footer class="footer-top-area align-self-center">
    <div class="container">
      <div class="row">
        <SubscriptionModel />
        <div class="col-lg-4 col-md-6 mt-5 text-left">
          <div class="justify-content-center">
            <ul>
              <li class="row justify-content-center">
                <button
                  class="footer-btn active"
                  @click.prevent="showMailModal()"
                >
                  <b-icon icon="envelope-fill" aria-hidden="true"></b-icon>
                  {{ $t("footer.news_letter") }}
                </button>
              </li>
              <li :class="['row', 'mt-4', 'justify-content-center']">
                <a
                  target="_blank"
                  href="https://www.facebook.com/kaohsiungexhibitioncenter"
                >
                  <div class="community-list">
                    <i class="fab fa-facebook-f"></i>
                  </div>
                </a>
                <a target="_blank" href="https://bit.ly/3A7AE8N">
                  <div class="community-list">
                    <i class="fab fa-instagram"></i>
                  </div>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC-N1zbVArVRQbBYV--MMHpg"
                >
                  <div class="community-list">
                    <i class="fab fa-youtube"></i>
                  </div>
                </a>
                <a target="_blank" href="http://bit.ly/2b4u2kD">
                  <div class="community-list">
                    <i class="fab fa-line"></i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-8 col-md-6 mt-5 text-left">
          <div class="single-widget d-flex justify-content-center">
            <ul class="footer-address">
              <li>{{ $t("footer.company") }}</li>
              <li>
                <a
                  target="_blank"
                  href="https://www.google.com.tw/maps/place/806%E9%AB%98%E9%9B%84%E5%B8%82%E5%89%8D%E9%8E%AE%E5%8D%80%E6%88%90%E5%8A%9F%E4%BA%8C%E8%B7%AF39%E8%99%9F%E9%AB%98%E9%9B%84%E5%B1%95%E8%A6%BD%E9%A4%A8/@22.6078659,120.2971173,17z/data=!3m1!4b1!4m5!3m4!1s0x346e03786848ef81:0x758740dc67754dd1!8m2!3d22.607861!4d120.299306?hl=zh-TW"
                  >{{ $t("footer.address") }}</a
                >
              </li>
              <li>
                <a href="tel:+88672131188">Tel：+886 7 213 1188</a>
                &nbsp;
                <a href="tel:+88672131100">Fax：+886 7 213 1100</a>
              </li>
              <li>
                <a href="mailto:info@kecc.com.tw">Email：info@kecc.com.tw</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr size="1" />
      <div>
        <footer class="footer-bottom-area">
          <div class="container">
            <div class="row align-items-center">
              <div
                class="col-lg-8 col-md-6 col-sm-6 text-left align-self-center reset-padding"
              >
                <div class="footeerCopyright">
                  <ul>
                    <li>
                      Copyright© 2022 Kaohsiung Exhibition Center Corp. <a target="_blank" class="bylink" href="https://cteam.com.tw/">Design by CTeam</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 col-sm-6 text-right align-self-center reset-padding"
              >
                <!-- <div class="row">
									<div class="col-md-8 text-right"> -->
                <div class="footeerCopyright">
                  <ul>
                    <a :href="this.$i18n.bindLang('/privacy')">
                      <li>
                        {{ $t("copyright.privacy") }}
                      </li>
                    </a>
                    <a :href="this.$i18n.bindLang('/terms')">
                      <li>
                        {{ $t("copyright.declaration") }}
                      </li>
                    </a>
                  </ul>
                </div>
                <!-- </div>
								</div> -->
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </footer>
</template>

<script>
import SubscriptionModel from "components/DefaultHome/KECC/Common/SubscriptionModel";
export default {
  name: "FooterTop",
  components: {
    SubscriptionModel,
  },
  data() {
    return {
      isSticky: false,
      navNameVisitor: "參觀訪客",
      navNameOrganizers: "主辦單位",
      navNamePress: "新聞媒體",
      navNameCompany: "關於我們",
      footerWidth: window.innerWidth >= 768 ? true : false,
      currentRouteName: '',
    };
  },
  created() {
        this.currentRouteName = this.$router.currentRoute.name;
    },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.footerWidth = window.innerWidth >= 768 ? true : false;
    });
  },
  methods: {
    showMailModal() {
      this.$root.$emit("bv::show::modal", `modal-subscription-${this.currentRouteName}`);
    },
  },
};
</script>

<style scoped>
.footeerCopyright a {
  color: #fff;
}
.footeerCopyright a:hover {
  color: #ffff00;
}
.footer-top-area {
  background-image: url("~assets/project_images/img/footer.jpg");
  /* background-color: #000066 !important; */
  background-size: cover;
  padding: 30px 0;
  background-color: rgb(0 40 169 / 60%);
  background-blend-mode: multiply;
}

.reset-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.footer-logo {
  width: 50%;
}

.footeerCopyright ul {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.footeerCopyright ul li {
  display: inline;
  padding-right: 0.5rem;
}

.footeerCopyright ul li a {
  /* display: inline-block;
  border: 2px solid #ccc;
  padding: 0 20px;
  color: #0033ff;
  text-decoration: none;
  font-size: 12px;
  line-height: 21px; */
  cursor: pointer;
}

.footer-bottom-area {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.footerTextColor {
  color: #ffffff;
}

.footerCommunityBtn {
  margin: 0.25vw;
  font-size: 40px;
  color: #ffffff;
  margin: 0 15px 0 0;
  /*----by wei---*/
}

.footerCommunityBtn i {
  color: #ffff00;
}

.footer-btn {
  font-size: 18px;
  /*----by wei---*/
  color: #fff;
  padding: 20px 60px;
  /*----by wei---*/
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 0.7px;
}

.footer-btn:after,
.footer-btn:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #0e0129;
  color: #fff;
  z-index: -1;
  transition: all 0.5s;
}

.footer-btn:hover {
  text-decoration: none;
  color: #007fff;
  /*----by wei---*/
  background-color: #ffff00;
  /*----by wei---*/
}

.footerNav ul {
  line-height: 35px;
}

footer hr {
  background-color: rgba(255, 255, 255, 0.25);
  height: 1px;
  border: none;
}

footer .container {
  max-width: 1000px;
}

.footer-m-p {
  padding-left: 2rem !important;
}

.single-widget ul li {
  font-size: 16px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .footeerCopyright ul {
    white-space: break-spaces;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .footeerCopyright ul {
    white-space: break-spaces;
  }

  .footer-m-p {
    text-align: center !important;
    padding-left: 5rem !important;
  }
}

.community-list {
  font-size: 26px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffff00 !important;
  margin: 0 5px;
}
.community-list:hover {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
}
</style>
