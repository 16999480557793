var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer-top-area align-self-center" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("SubscriptionModel"),
          _c("div", { staticClass: "col-lg-4 col-md-6 mt-5 text-left" }, [
            _c("div", { staticClass: "justify-content-center" }, [
              _c("ul", [
                _c("li", { staticClass: "row justify-content-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "footer-btn active",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showMailModal()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "envelope-fill", "aria-hidden": "true" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("footer.news_letter")) + " ")
                    ],
                    1
                  )
                ]),
                _c("li", { class: ["row", "mt-4", "justify-content-center"] }, [
                  _vm._m(0),
                  _vm._m(1),
                  _vm._m(2),
                  _vm._m(3)
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "col-lg-8 col-md-6 mt-5 text-left" }, [
            _c(
              "div",
              { staticClass: "single-widget d-flex justify-content-center" },
              [
                _c("ul", { staticClass: "footer-address" }, [
                  _c("li", [_vm._v(_vm._s(_vm.$t("footer.company")))]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href:
                            "https://www.google.com.tw/maps/place/806%E9%AB%98%E9%9B%84%E5%B8%82%E5%89%8D%E9%8E%AE%E5%8D%80%E6%88%90%E5%8A%9F%E4%BA%8C%E8%B7%AF39%E8%99%9F%E9%AB%98%E9%9B%84%E5%B1%95%E8%A6%BD%E9%A4%A8/@22.6078659,120.2971173,17z/data=!3m1!4b1!4m5!3m4!1s0x346e03786848ef81:0x758740dc67754dd1!8m2!3d22.607861!4d120.299306?hl=zh-TW"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.address")))]
                    )
                  ]),
                  _vm._m(4),
                  _vm._m(5)
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c("hr", { attrs: { size: "1" } }),
      _c("div", [
        _c("footer", { staticClass: "footer-bottom-area" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _vm._m(6),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-4 col-md-6 col-sm-6 text-right align-self-center reset-padding"
                },
                [
                  _c("div", { staticClass: "footeerCopyright" }, [
                    _c("ul", [
                      _c(
                        "a",
                        { attrs: { href: this.$i18n.bindLang("/privacy") } },
                        [
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("copyright.privacy")) + " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a",
                        { attrs: { href: this.$i18n.bindLang("/terms") } },
                        [
                          _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("copyright.declaration")) +
                                " "
                            )
                          ])
                        ]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          target: "_blank",
          href: "https://www.facebook.com/kaohsiungexhibitioncenter"
        }
      },
      [
        _c("div", { staticClass: "community-list" }, [
          _c("i", { staticClass: "fab fa-facebook-f" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { target: "_blank", href: "https://bit.ly/3A7AE8N" } },
      [
        _c("div", { staticClass: "community-list" }, [
          _c("i", { staticClass: "fab fa-instagram" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          target: "_blank",
          href: "https://www.youtube.com/channel/UC-N1zbVArVRQbBYV--MMHpg"
        }
      },
      [
        _c("div", { staticClass: "community-list" }, [
          _c("i", { staticClass: "fab fa-youtube" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { target: "_blank", href: "http://bit.ly/2b4u2kD" } },
      [
        _c("div", { staticClass: "community-list" }, [
          _c("i", { staticClass: "fab fa-line" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "tel:+88672131188" } }, [
        _vm._v("Tel：+886 7 213 1188")
      ]),
      _vm._v("   "),
      _c("a", { attrs: { href: "tel:+88672131100" } }, [
        _vm._v("Fax：+886 7 213 1100")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "mailto:info@kecc.com.tw" } }, [
        _vm._v("Email：info@kecc.com.tw")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-8 col-md-6 col-sm-6 text-left align-self-center reset-padding"
      },
      [
        _c("div", { staticClass: "footeerCopyright" }, [
          _c("ul", [
            _c("li", [
              _vm._v(" Copyright© 2022 Kaohsiung Exhibition Center Corp. "),
              _c(
                "a",
                {
                  staticClass: "bylink",
                  attrs: { target: "_blank", href: "https://cteam.com.tw/" }
                },
                [_vm._v("Design by CTeam")]
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }