var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "lang-btn" }, [
      _c(
        "a",
        { attrs: { href: this.$i18n.bindLang(this.$route.path, "zh-tw") } },
        [
          _c(
            "div",
            { class: ["shape-ex5-block", { "is-lang": _vm.lang_tw }] },
            [_vm._v("中")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "lang-btn bg-white" }, [
      _c(
        "a",
        { attrs: { href: this.$i18n.bindLang(this.$route.path, "en-us") } },
        [
          _c(
            "div",
            { class: ["shape-ex5-block", { "is-lang": _vm.lang_en }] },
            [_vm._v("EN")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }