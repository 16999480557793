<template>
  <div class="hot-button">
    <a :href="this.$i18n.bindLang('/contact-us')">
      <div
        :class="[
          'email-btn',
          { 'is-active': isSticky },
          'animated pulse infinite',
        ]"
      >
        <b-icon
          class="icon-color"
          icon="envelope-fill"
          aria-hidden="true"
        ></b-icon>
      </div>
    </a>
    <div
      :class="['top-scroll-speed', { 'is-active': isSticky }]"
      @click="backToTop"
    >
      <b-icon class="icon-color" icon="arrow-up"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "BacktopButton",
  create: function() {},
  data() {
    return {
      isSticky: true,
    };
  },

  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = false;
      } else {
        that.isSticky = true;
      }
    });
  },
  methods: {
    backToTop: function() {
      var scrollToTop = window.setInterval(function() {
        var pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    },
  },
};
</script>

<style scoped>
.hot-button {
  display: flex;
}
.email-btn {
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #33ccff !important;
}

.email-btn .icon-color {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 30px;
}

.top-scroll-speed {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #33ccff !important;
}

.top-scroll-speed .icon-color {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 30px;
}

.icon-color {
  color: #ffffff;
}

.top-scroll-speed:hover {
  transition: 0.5s;
  transform: scale(1.2, 1.2);
}

.email-btn:hover {
  transition: 0.5s;
  transform: scale(1.2, 1.2);
}
</style>
