<template>
    <div class="row justify-content-center">
        <div class="lang-btn">
            <a :href="this.$i18n.bindLang(this.$route.path,'zh-tw')">
            <div :class="['shape-ex5-block', {'is-lang': lang_tw}]">中</div>
            </a>
        </div>
        <div class="lang-btn bg-white">
            <a :href="this.$i18n.bindLang(this.$route.path,'en-us')">
            <div :class="['shape-ex5-block', {'is-lang': lang_en}]">EN</div>
            </a>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'LangSelect',
        data() {
            return {
                langs: ['繁體中文', 'English', '日本語'],
                lang_tw: true,
                lang_en: false,
            }
        },
        mounted() {
            let lang = this.$route.params.language
            if (lang == "zh-tw") {
                this.lang_tw = true
                this.lang_en = false
            }
            if (lang == "en-us") {
                this.lang_tw = false
                this.lang_en = true
            }

        },
        methods: {
        }
    }
</script>

<style>
    .lang-btn {
        width: 30px;
        height: 30px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ffffff !important;
        margin: 0.2rem;
        cursor: pointer;
        font-size: 16px;
        transition: all .2s ease-in-out;
    }
    .lang-btn a{

        color:#000033;
    }
    .lang-btn:hover{
        transform: scale(1.1);
    }
    .bg-white {
        background-color: #ffffff !important;
    }

    .is-lang {
        width: 30px;
        height: 30px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ffff00 !important;
        cursor: pointer;
        font-size: 16px;
    }
</style>