var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header-area fixed-top" }, [
    _c("div", { staticClass: "nav-area" }, [
      _c(
        "div",
        {
          class: [
            "navbar-area",
            { "is-sticky": _vm.isSticky },
            { "is-nav-moblie-scroll": !_vm.isNavMoblieScroll }
          ]
        },
        [
          _c("div", { staticClass: "main-nav" }, [
            _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
              _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _c(
                    "router-link",
                    {
                      class: ["navbar-brand"],
                      attrs: { to: this.$i18n.bindLang("/") }
                    },
                    [
                      _c("div", [
                        _c("img", {
                          staticClass: "nav-logo-image main",
                          attrs: {
                            src: require("assets/project_images/svg/item_KEC-logo-white.svg"),
                            alt: "image"
                          }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "b-navbar-toggle",
                    { attrs: { target: "navbarSupportedContent" } },
                    [
                      _c("span", { staticClass: "icon-bar top-bar" }),
                      _c("span", { staticClass: "icon-bar middle-bar" }),
                      _c("span", { staticClass: "icon-bar bottom-bar" })
                    ]
                  ),
                  _c(
                    "b-collapse",
                    {
                      staticClass:
                        "collapse navbar-collapse mean-menu text-center",
                      attrs: { id: "navbarSupportedContent", "is-nav": "" }
                    },
                    [
                      _c("ul", { staticClass: "navbar-nav m-auto" }, [
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-li-font",
                                on: { click: _vm.isVisitorFun }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("navbar.menu_1")) + " "
                                )
                              ]
                            ),
                            _c("transition", { attrs: { name: "bounce" } }, [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isVisitor,
                                      expression: "isVisitor"
                                    }
                                  ],
                                  staticClass: "dropdown-menu"
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/visitors-exhibition"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_1_1")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/traffic")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_1_2")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/visitors-facility"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_1_3")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/accommodation"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_1_5")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("li", { staticClass: "nav-item" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          href:
                                            "https://khh.travel/" +
                                            (this.$i18n.locale == "en-us"
                                              ? "en"
                                              : ""),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("navbar.menu_1_4")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-li-font",
                                on: { click: _vm.isOrganizersFun }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("navbar.menu_2")) + " "
                                )
                              ]
                            ),
                            _c("transition", { attrs: { name: "bounce" } }, [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isOrganizers,
                                      expression: "isOrganizers"
                                    }
                                  ],
                                  staticClass: "dropdown-menu"
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/rental-venue"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_2_3")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/organizers-facility"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_2_4")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/organizers-album"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_2_5")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/organizers-document"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_2_6")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("li", { staticClass: "nav-item" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          href: "https://5gsmartvenue.com.tw",
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("navbar.menu_2_2")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-li-font",
                                on: { click: _vm.isNewsFun }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("navbar.menu_3")) + " "
                                )
                              ]
                            ),
                            _c("transition", { attrs: { name: "bounce" } }, [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isNews,
                                      expression: "isNews"
                                    }
                                  ],
                                  staticClass: "dropdown-menu"
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/news")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_3_1")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/press-album"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_3_2")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/video")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_3_3")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/press-document"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_3_4")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-li-font",
                                on: { click: _vm.isAboutFun }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("navbar.menu_4")) + " "
                                )
                              ]
                            ),
                            _c("transition", { attrs: { name: "bounce" } }, [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isAbout,
                                      expression: "isAbout"
                                    }
                                  ],
                                  staticClass: "dropdown-menu"
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/kec")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_4_1")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/esg")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_4_5")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/team")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_4_2")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang("/join-us")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_4_3")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    { staticClass: "nav-item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            to: this.$i18n.bindLang(
                                              "/contact-us"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("navbar.menu_4_4")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("LangSelect", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: true,
                            expression: "true"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }