var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            id: "modal-subscription-" + _vm.currentRouteName,
            centered: "",
            title: this.$t("newsletter.title")
          }
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "container-fuild" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-12 text-center" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/pic/subscribe.jpg"),
                        alt: "image"
                      }
                    }),
                    _c(
                      "b-form",
                      {
                        staticClass: "mt-3",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.sendRequestSubscription()
                          }
                        }
                      },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                placeholder: this.$t("newsletter.content"),
                                type: "email",
                                required: "",
                                id: "sub-email"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        !_vm.sending
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.sendRequestSubscription()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("newsletter.button")) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.sending
                          ? _c(
                              "b-button",
                              { attrs: { variant: "primary", disabled: "" } },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("newsletter.sending_message")
                                    ) +
                                    "... "
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }